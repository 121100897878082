export const environment = {
  production: false,
  test:"dev environment",
  homePageUrl: "https://dev-home.hydronoslabs.com",
  epaUrl: "https://stko-roy.geog.ucsb.edu:7202/repositories/KWG",
  droughtUrl: "http://stko-roy.geog.ucsb.edu:7202/repositories/KWG",
  //serverFireUrl: "https://stko-kwg.geog.ucsb.edu/graphdb/repositories/KWG",
  serverFireUrl: "https://stko-kwg.geog.ucsb.edu/graphdb/repositories/KWG-V1",
  plumeUrl: "https://stko-kwg.geog.ucsb.edu/graphdb/repositories/KWG-V1",
  KWGV1: "https://stko-kwg.geog.ucsb.edu/graphdb/repositories/KWG-V1",
  KWGV3: "https://stko-kwg.geog.ucsb.edu/graphdb/repositories/KWG-V3",
  KWG:"https://stko-kwg.geog.ucsb.edu/graphdb/repositories/KWG",
  STAGING:"https://staging.knowwheregraph.org/graphdb/repositories/KWG"
};